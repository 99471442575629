import {
  Container,
  Typography,
  TextField,
  Button,
  Checkbox,
  Box,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import HeaderComponent from "../components/HeaderComponent";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Services from "../services/Services";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Language from "../languages";
import { encrypt } from "../utils/encrypt.js";
import test from "../assets/htmls/test.js";
// import aydinlatma from "../assets/contracts/aydinlatma.pdf";
// import mesafeli from "../assets/contracts/test2.pdf";

// import aydinlatma from "../assets/contracts/aydinlatma.docx"

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function LoginPage() {
  const navigate = useNavigate();
  const projectName = useSelector((state) => state.projectName);
  const mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);
  const uri = localStorage.getItem("mallUri");
  const [step, setStep] = useState(0);
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [userCode, setUserCode] = useState("");
  const [showCode, setShowCode] = useState(false);
  const [disabledPhone, setDisabledPhone] = useState(false);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showSalesContractModal, setShowSalesContractModal] = useState(false);
  const [showKVKKModal, setShowKVKKModal] = useState(false);

  const [agreement1, setAgreement1] = useState("");
  const [agreement2, setAgreement2] = useState("");

  const userControl = () => {
    Services.GetCustomer(token, phone)
      .then((res) => {
        console.log("Get Customer Response: ", res.data);
        if (res.data.status) {
          localStorage.setItem("name", res.data.result.name);
          localStorage.setItem("lastname", res.data.result.lastname);
          localStorage.setItem("phone", res.data.result.phone);
          localStorage.setItem("customerID", res.data.result.customerID);
          console.log(
            "Hashed Code: ",
            encrypt(res.data.result.phone, "handsfreeAvm*24")
          );
          localStorage.setItem(
            "hashedCode",
            encrypt(res.data.result.phone, "handsfreeAvm*24")
          );
          navigate(`/${uri}`);
        } else {
          setShowCode(false);
          setDisabledPhone(true);
          setStep(1);
        }
      })
      .catch((err) => {
        // console.log(err);
        setShowCode(false);
        setDisabledPhone(true);
        setStep(1);
      });
  };

  const sendSMS = (phone) => {
    const _token = localStorage.getItem("token");
    setToken(_token);
    const _code = Math.floor(100000 + Math.random() * 900000).toString();
    setCode(_code);
    const message =
      language === "tr"
        ? `Telefon numaranızı doğrulamanız için ${_code} kodunu gerekli alana giriniz.`
        : `Enter code ${_code} in the required field to verify your phone number.`;
    // console.log(_token, phone, message);
    Services.SendSMS(_token, phone, message)
      .then((res) => {
        // console.log("sms: ", res.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleContinue = () => {
    if (step === 0) {
      setShowCode(true);
      if (userCode !== "") {
        if (userCode === code) {
          userControl();
        } else {
          window.alert(
            language === "tr"
              ? "Kodlar uyuşmuyor, tekrar deneyiniz."
              : "Codes don't match, please try again."
          );
        }
      } else {
        sendSMS(phone);
      }
    } else {
      console.log("Kullanıcı yok");
      localStorage.setItem("name", name);
      localStorage.setItem("lastname", lastname);
      localStorage.setItem("phone", phone);
      console.log("Hashed Code: ", encrypt(phone, "handsfreeAvm*24"));
      localStorage.setItem("hashedCode", encrypt(phone, "handsfreeAvm*24"));
      navigate(`/${uri}`);
    }
  };

  useEffect(() => {
    Services.GetToken().then((res) => {
      Services.GetAgreement(res.data.result.token, "1", language).then(
        (response) => {
          console.log("1", response.data);
          setAgreement1(response.data.result);
        }
      );
      Services.GetAgreement(res.data.result.token, "2", language).then(
        (response) => {
          console.log("2", response.data);
          setAgreement2(response.data.result);
        }
      );
    });
  }, [language]);

  return (
    <Container>
      <Box sx={{ my: 4, mx: 2 }}>
        <HeaderComponent
          isLogin={true}
          middle="logo"
          goBack={() => navigate(`/${uri}`)}
          left="back"
          right="icon"
        />

        <Box sx={{ marginTop: 10 }}>
          <Typography
            variant="h5"
            component="h4"
            textAlign="center"
            sx={{
              lineHeight: "26.63px",
              fontSize: 22,
              fontWeight: 700,
              marginBottom: 2,
            }}
          >
            {projectName}
          </Typography>

          {step === 1 && (
            <>
              <TextField
                id="outlined-basic-1"
                label={Language(mall, language, "name")}
                variant="outlined"
                fullWidth
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputLabelProps={{
                  style: { color: "#C1C6C9", fontSize: 15 },
                }}
                sx={{
                  marginBottom: 2,
                  backgroundColor: "#ffffff",
                  borderColor: "#C1C6C9",
                }}
              />
              <TextField
                id="outlined-basic-2"
                label={Language(mall, language, "lastname")}
                variant="outlined"
                fullWidth
                name="lastname"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                InputLabelProps={{
                  style: { color: "#C1C6C9", fontSize: 15 },
                }}
                sx={{
                  marginBottom: 2,
                  backgroundColor: "#ffffff",
                  borderColor: "#C1C6C9",
                }}
              />
            </>
          )}

          <TextField
            id="outlined-basic-3"
            label={Language(mall, language, "phone")}
            variant="outlined"
            placeholder="5XXXXXXXXX"
            fullWidth
            name="phone"
            value={phone}
            disabled={disabledPhone}
            onChange={(e) => setPhone(e.target.value)}
            InputLabelProps={{
              style: { color: "#C1C6C9", fontSize: 15 },
            }}
            sx={{
              marginBottom: 5,
              backgroundColor: "#ffffff",
              borderColor: "#C1C6C9",
            }}
          />

          {showCode && (
            <>
              {" "}
              <TextField
                id="outlined-basic-3"
                label={Language(mall, language, "code")}
                variant="outlined"
                fullWidth
                name="userCode"
                value={userCode}
                onChange={(e) => setUserCode(e.target.value)}
                InputLabelProps={{
                  style: { color: "#C1C6C9", fontSize: 15 },
                }}
                sx={{
                  backgroundColor: "#ffffff",
                  borderColor: "#C1C6C9",
                  marginBottom: "20px",
                }}
              />
              <FormGroup fullWidth>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      {...label}
                      checked={checkbox1}
                      onChange={() => {
                        if (checkbox1 === false) setShowTermsModal(true);
                        else setCheckbox1(false);
                      }}
                      name="checkbox1"
                      defaultChecked
                      color="newRed"
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#5E596D",
                        lineHeight: "19.5px",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {language === "tr"
                        ? `Kullanıcı Sözleşmesi’ni okudum, anladım, onaylıyorum.`
                        : ` 
                        I have read, understood and approve the User Agreement.`}
                    </Typography>
                  }
                  sx={{ marginBottom: 3 }}
                /> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkbox2}
                      onChange={
                        () => {
                          if (checkbox2 === false) setShowKVKKModal(true);
                          else setCheckbox2(false);
                        }
                        // setCheckbox2(!checkbox2)
                      }
                      name="checkbox2"
                      {...label}
                      defaultChecked
                      color="newRed"
                    />
                  }
                  label={
                    <>
                      {language === "tr" ? (
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "#5E596D",
                            lineHeight: "19.5px",
                            fontSize: 13,
                            fontWeight: 600,
                          }}
                        >
                          Avm Lojistik Tic. A.Ş şirketi’nden almış olduğum
                          hizmet kapsamında, 6698 sayılı kanun uyarınca, bilgime
                          sunulan Aydınlatma Metnini okudum, anladım,
                          onaylıyorum.
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "#5E596D",
                            lineHeight: "19.5px",
                            fontSize: 13,
                            fontWeight: 600,
                          }}
                        >
                          Avm Lojistik Tic. A.Ş. within the scope of the service
                          I have received from the company, in accordance with
                          the law numbered 6698, I have read, understood and
                          approved the Clarification Text submitted to my
                          knowledge.
                        </Typography>
                      )}
                    </>
                  }
                  sx={{ marginBottom: 3 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      {...label}
                      checked={checkbox3}
                      onChange={() => {
                        setCheckbox3(!checkbox3);
                      }}
                      name="checkbox3"
                      defaultChecked
                      color="newRed"
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#5E596D",
                        lineHeight: "19.5px",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {Language(mall, language, "consent")}
                    </Typography>
                  }
                  sx={{ marginBottom: 3 }}
                />
              </FormGroup>
            </>
          )}

          <Button
            variant="contained"
            color="newRed"
            fullWidth
            sx={{ paddingY: 1.2 }}
            onClick={handleContinue}
            disabled={loading || (showCode && (!checkbox2 || !checkbox3))}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              Language(mall, language, "continue")
            )}
          </Button>
          {showCode && (
            <Button
              variant="text"
              color="newRed"
              fullWidth
              sx={{ paddingY: 1.2, marginTop: "20px" }}
              onClick={() => sendSMS(phone)}
            >
              {Language(mall, language, "codeAgain")}
            </Button>
          )}
        </Box>
      </Box>

      <Dialog
        open={showTermsModal}
        onClose={() => setShowTermsModal(false)}
        maxWidth="lg"
      >
        <DialogTitle>
          {language === "tr" ? "Koşullar" : "Conditions"}
        </DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: agreement2 }} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCheckbox1(true);
              setShowTermsModal(false);
            }}
            color="primary"
          >
            {Language(mall, language, "agree")}
          </Button>
          <Button onClick={() => setShowTermsModal(false)} color="primary">
            {Language(mall, language, "close")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* KVKK Modal */}
      <Dialog
        open={showKVKKModal}
        onClose={() => setShowKVKKModal(false)}
        maxWidth="lg"
      >
        <DialogTitle>
          {language === "tr" ? "KVKK Politikası" : "KVKK Policy"}
        </DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: agreement1 }} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCheckbox2(true);
              setShowKVKKModal(false);
            }}
            color="primary"
          >
            {Language(mall, language, "agree")}
          </Button>
          <Button onClick={() => setShowKVKKModal(false)} color="primary">
            {Language(mall, language, "close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
