import {
  Container,
  Typography,
  TextField,
  Button,
  Checkbox,
  Box,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import HeaderComponent from "../components/HeaderComponent";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Services from "../services/Services";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { UseSelector, useSelector } from "react-redux";
import Language from "../languages";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function MainPage() {
  const navigate = useNavigate();
  const projectName = useSelector((state) => state.projectName);
  const _mall = useSelector((state) => state.mall);
  const language = useSelector((state) => state.language);

  const { t, i18n } = useTranslation();
  const uri = localStorage.getItem("mallUri");
  const storedMall = localStorage.getItem("mall");
  const [loginType, setLoginType] = useState("2");
  const [formData, setFormData] = useState({
    firm: "",
    name: "",
    lastname: "",
    phone: "",
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });
  const [additionalNote, setAdditionalNote] = useState("");
  const [mall, setMall] = useState();
  const [code, setCode] = useState("");
  const [userCode, setUserCode] = useState("");
  const [showCode, setShowCode] = useState(false);
  const [disabledPhone, setDisabledPhone] = useState(false);
  const [token, setToken] = useState("");
  const [urlToken, setUrlToken] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showSalesContractModal, setShowSalesContractModal] = useState(false);
  const [showKVKKModal, setShowKVKKModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [firstLogin, setFirstLogin] = useState(false);
  const [firstLoginComplete, setFirstLoginComplete] = useState(false);
  const [showRoomNo, setShowRoomNo] = useState(false);

  const handleTermsClick = () => {
    setShowTermsModal(true);
  };

  const handleSalesContractClick = () => {
    setShowSalesContractModal(true);
  };

  const handleKVKKClick = () => {
    setShowKVKKModal(true);
  };

  const handleSuccess = () => {
    setSuccess(true);
    setLoading(false);
  };

  const handleFailed = () => {
    setFailed(true);
    setLoading(false);
  };

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCreate = (token, name, lastname, phone, firm, additionalNote) => {
    Services.CreateOrder(
      token,
      name,
      lastname,
      phone,
      firm,
      `${name} ${lastname} ${additionalNote}`
    )
      .then((res) => {
        // console.log("create order response:", res.data);
        if (res.data.status) {
          if (firstLogin) {
            localStorage.setItem("name", name);
            localStorage.setItem("lastname", lastname);
            localStorage.setItem("phone", phone);
          }
          handleSuccess();
        } else {
          handleFailed();
        }
      })
      .catch((err) => {
        // console.log(err);
        handleFailed();
      });
  };

  const userControl = () => {
    Services.GetCustomer(token, formData.phone)
      .then((res) => {
        // console.log("Get Customer Response: ", res.data);
        if (res.data.status) {
          localStorage.setItem("name", res.data.result.name);
          localStorage.setItem("lastname", res.data.result.lastname);
          localStorage.setItem("phone", res.data.result.phone);
          localStorage.setItem("customerID", res.data.result.customerID);
          setLoginType("3");
          setShowCode(false);
          setDisabledPhone(true);
          setFirstLoginComplete(true);
          setFormData({
            name: res.data.result.name,
            lastname: res.data.result.lastname,
            phone: res.data.result.phone,
          });
        } else {
          setLoginType("3");
          setShowCode(false);
          setDisabledPhone(true);
          setFirstLoginComplete(true);
        }
      })
      .catch((err) => {
        // console.log(err);
        setLoginType("3");
        setShowCode(false);
        setDisabledPhone(true);
        setFirstLoginComplete(true);
      });
  };

  const sendSMS = (phone) => {
    const _code = Math.floor(100000 + Math.random() * 900000).toString();
    setCode(_code);
    const message = `Telefon numaranızı doğrulamanız için ${_code} kodunu gerekli alana giriniz.`;
    Services.SendSMS(token, phone, message)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleContinue = () => {
    const { name, firm, lastname, phone, checkbox1, checkbox2 } = formData;
    //  console.log(
    //   "parametreler: ",
    //   name,
    //   lastname,
    //   phone,
    //   firm,
    //   checkbox1,
    //   checkbox2,
    //   additionalNote
    // );

    if (firstLogin && firstLoginComplete === false) {
      if (loginType === "2") {
        setShowCode(true);
        if (showCode) {
          if (userCode === code) {
            userControl();
          } else {
            window.alert(
              language === "tr"
                ? "Kodlar uyuşmuyor, tekrar deneyiniz."
                : "Codes don't match, please try again."
            );
          }
        } else {
          sendSMS(phone);
        }
      }
    } else {
      setLoading(true);
      handleCreate(token, name, lastname, phone, firm, additionalNote);
    }
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedName = localStorage.getItem("name");
    const storedLastname = localStorage.getItem("lastname");
    const storedPhone = localStorage.getItem("phone");
    // // console.log("mall: ", _mall);
    setToken(storedToken);
    // // console.log("stored Mall: ", storedMall);
    if (storedMall) {
      const parsedMall = JSON.parse(storedMall);
      // // console.log("parsedMall: ", parsedMall);
      if (parsedMall[0]?.uri === "istinyepark-izmir") {
        setShowRoomNo(true);
      }
      setMall(parsedMall);
    } else {
      // burada avm seçilmesi için bir state tetiklenmeli
    }
    if (storedName) {
      setFormData((prevData) => ({
        ...prevData,
        name: storedName,
        lastname: storedLastname,
        phone: storedPhone,
      }));
      setDisabledPhone(true);

      setFirstLogin(false);
      setLoginType("1");
    } else {
      setFormData((prevData) => ({
        ...prevData,
        name: "",
        lastname: "",
        phone: "",
      }));
      setDisabledPhone(false);

      setFirstLogin(true);
      setLoginType("2");
    }
  }, [storedMall]);

  return (
    <Container>
      <Box sx={{ my: 4, mx: 2 }}>
        <HeaderComponent
          middle="logo"
          goBack={() => navigate(`/${uri}`)}
          left="back"
          right="icon"
        />

        <Box sx={{ marginTop: 10 }}>
          <Typography
            variant="h5"
            component="h4"
            textAlign="center"
            sx={{
              lineHeight: "26.63px",
              fontSize: 22,
              fontWeight: 700,
              marginBottom: 2,
            }}
          >
            {projectName}
          </Typography>
          <Typography
            variant="subtitle2"
            component="h4"
            textAlign="center"
            marginX={8}
            sx={{
              color: "#5E596D",
              lineHeight: "16.94px",
              fontSize: 14,
              fontWeight: 400,
              marginBottom: 5,
            }}
          >
            {loginType === "1" || loginType === "3"
              ? Language(_mall, language, "selectStoreTitle")
              : Language(_mall, language, "selectPhone")}
          </Typography>

          {(loginType === "1" || loginType === "3") && (
            <>
              <FormControl fullWidth>
                <InputLabel
                  style={{ color: "#C1C6C9", fontSize: 15 }}
                  id="demo-simple-select-label"
                >
                  {/* {t("firm")}* */}
                  {Language(_mall, language, "firm")}*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={Language(_mall, language, "firm")}
                  name="firm"
                  value={formData.firm}
                  onChange={handleInputChange}
                  sx={{
                    marginBottom: 2,
                    backgroundColor: "#ffffff",
                    borderColor: "#C1C6C9",
                  }}
                >
                  {mall &&
                    mall[0].stores.map((store) => (
                      <MenuItem key={store.id} value={store.id}>
                        {store.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                id="outlined-basic-1"
                label={`${Language(_mall, language, "name")}*`}
                variant="outlined"
                fullWidth
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                InputLabelProps={{
                  style: { color: "#C1C6C9", fontSize: 15 },
                }}
                sx={{
                  marginBottom: 2,
                  backgroundColor: "#ffffff",
                  borderColor: "#C1C6C9",
                }}
              />
              <TextField
                id="outlined-basic-2"
                label={`${Language(_mall, language, "lastname")}*`}
                variant="outlined"
                fullWidth
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
                InputLabelProps={{
                  style: { color: "#C1C6C9", fontSize: 15 },
                }}
                sx={{
                  marginBottom: 2,
                  backgroundColor: "#ffffff",
                  borderColor: "#C1C6C9",
                }}
              />
            </>
          )}

          <TextField
            id="outlined-basic-3"
            label={`${Language(_mall, language, "phone")}*`}
            variant="outlined"
            placeholder="5XXXXXXXXX"
            fullWidth
            name="phone"
            disabled={disabledPhone}
            value={formData.phone}
            onChange={handleInputChange}
            InputLabelProps={{
              style: { color: "#C1C6C9", fontSize: 15 },
            }}
            sx={{
              marginBottom: showRoomNo ? 2 : 5,
              backgroundColor: "#ffffff",
              borderColor: "#C1C6C9",
            }}
          />
          {showRoomNo && (loginType === "3" || loginType === "1") && (
            <TextField
              id="outlined-basic-4"
              label={`${Language(_mall, language, "roomNo")}*`}
              variant="outlined"
              fullWidth
              name="roomNo"
              value={additionalNote}
              onChange={(e) => setAdditionalNote(e.target.value)}
              InputLabelProps={{
                style: { color: "#C1C6C9", fontSize: 15 },
              }}
              sx={{
                marginBottom: 5,
                backgroundColor: "#ffffff",
                borderColor: "#C1C6C9",
              }}
            />
          )}

          {showCode && (
            <TextField
              id="outlined-basic-3"
              label={`${Language(_mall, language, "code")}*`}
              variant="outlined"
              fullWidth
              name="userCode"
              value={userCode}
              onChange={(e) => setUserCode(e.target.value)}
              InputLabelProps={{
                style: { color: "#C1C6C9", fontSize: 15 },
              }}
              sx={{
                backgroundColor: "#ffffff",
                borderColor: "#C1C6C9",
                marginBottom: "20px",
              }}
            />
          )}

          {(loginType === "1" || loginType === "3") && (
            <FormGroup fullWidth>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    {...label}
                    checked={formData.checkbox1}
                    onChange={handleInputChange}
                    name="checkbox1"
                    defaultChecked
                    color="newRed"
                  />
                }
                label={
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#5E596D",
                      lineHeight: "19.5px",
                      fontSize: 13,
                      fontWeight: 600,
                    }}
                  >
                   
                    {Language(_mall, language, "conditions1", projectName)}{" "}
                    <span
                      onClick={handleTermsClick}
                      style={{ color: "#FF6E60", fontWeight: 700 }}
                    >
          
                      {Language(_mall, language, "conditions2")}
                    </span>{" "}

                    {Language(_mall, language, "conditions3", projectName)}
                  </Typography>
                }
                sx={{ marginBottom: 3 }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.checkbox2}
                    onChange={handleInputChange}
                    name="checkbox2"
                    {...label}
                    defaultChecked
                    color="newRed"
                  />
                }
                label={
                  <>
                    {language === "tr" ? (
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "#5E596D",
                          lineHeight: "19.5px",
                          fontSize: 13,
                          fontWeight: 600,
                        }}
                      >
                        <span
                          onClick={handleSalesContractClick}
                          style={{ color: "#FF6E60", fontWeight: 700 }}
                        >
                          Mesafeli Satış Sözleşmesini
                        </span>{" "}
                        ve{" "}
                        <span
                          onClick={handleKVKKClick}
                          style={{ color: "#FF6E60", fontWeight: 700 }}
                        >
                          KVKK
                        </span>{" "}
                        Politikası'nı okudum ve onaylıyorum.
                      </Typography>
                    ) : (
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "#5E596D",
                          lineHeight: "19.5px",
                          fontSize: 13,
                          fontWeight: 600,
                        }}
                      >
                        <span>I have read and approve the </span>
                        <span
                          onClick={handleSalesContractClick}
                          style={{ color: "#FF6E60", fontWeight: 700 }}
                        >
                          Distance Sales Agreement
                        </span>{" "}
                        and{" "}
                        <span
                          onClick={handleKVKKClick}
                          style={{ color: "#FF6E60", fontWeight: 700 }}
                        >
                          KVKK
                        </span>{" "}
                        Policy.
                      </Typography>
                    )}
                  </>
                }
                sx={{ marginBottom: 3 }}
              /> */}
              {_mall === "galataport" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...label}
                      checked={formData.checkbox3}
                      onChange={handleInputChange}
                      name="checkbox3"
                      defaultChecked
                      color="newRed"
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#5E596D",
                        lineHeight: "19.5px",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {/* {t("taxFree")} */}
                      {Language(_mall, language, "taxFree")}
                    </Typography>
                  }
                  sx={{ marginBottom: 5 }}
                />
              )}
            </FormGroup>
          )}

          <Button
            variant="contained"
            color="newRed"
            fullWidth
            sx={{ paddingY: 1.2 }}
            onClick={handleContinue}
            disabled={
              (loginType !== "2" &&
                (loading ||
                  formData.name === "" ||
                  formData.lastname === "" ||
                  formData.firm === "")) ||
              (loginType !== "2" && showRoomNo && additionalNote === "")
            }
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              // `${t("continue")}`
              Language(_mall, language, "continue")
            )}
          </Button>
          {showCode && (
            <Button
              variant="text"
              color="newRed"
              fullWidth
              sx={{ paddingY: 1.2, marginTop: "20px" }}
              onClick={() => sendSMS(formData.phone)}
            >
              {Language(_mall, language, "codeAgain")}
            </Button>
          )}
        </Box>
      </Box>

      <Dialog open={showTermsModal} onClose={() => setShowTermsModal(false)}>
        <DialogTitle>Koşullar</DialogTitle>
        <DialogContent>
          {/* Koşullar içeriği buraya gelecek */}
          {/* Örneğin: <Typography>Bu koşulları kabul ediyorsunuz.</Typography> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTermsModal(false)} color="primary">
            {/* {t("close")} */}
            {Language(_mall, language, "close")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Mesafeli Satış Sözleşmesi Modal */}
      <Dialog
        open={showSalesContractModal}
        onClose={() => setShowSalesContractModal(false)}
      >
        <DialogTitle>Mesafeli Satış Sözleşmesi</DialogTitle>
        <DialogContent>
          {/* Mesafeli Satış Sözleşmesi içeriği buraya gelecek */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowSalesContractModal(false)}
            color="primary"
          >
            {Language(_mall, language, "close")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* KVKK Modal */}
      <Dialog open={showKVKKModal} onClose={() => setShowKVKKModal(false)}>
        <DialogTitle>KVKK Politikası</DialogTitle>
        <DialogContent>
          {/* KVKK Politikası içeriği buraya gelecek */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowKVKKModal(false)} color="primary">
            {Language(_mall, language, "close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={success} onClose={() => setSuccess(false)}>
        <DialogTitle color={"green"}>
          {Language(_mall, language, "success")}
        </DialogTitle>
        <DialogContent>
          <Typography>{Language(_mall, language, "successMessage")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSuccess(false);
              navigate(`/${uri}`);
            }}
            color="primary"
          >
            {/* {t("okay")} */}
            {Language(_mall, language, "okay")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={failed} onClose={() => setFailed(false)}>
        <DialogTitle color={"red"}>
          {Language(_mall, language, "error")}
        </DialogTitle>
        <DialogContent>
          <Typography>{Language(_mall, language, "errorMessage")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFailed(false)} color="primary">
            {Language(_mall, language, "okay")}
          </Button>
        </DialogActions>
      </Dialog>

      <Typography
        sx={{
          color: "black",
          fontSize: 8,
          bottom: 5,
          textAlign: "center",
        }}
      >
        {Language(_mall, language, "version")} 0.2.4
      </Typography>
    </Container>
  );
}
