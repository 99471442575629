import { useRoutes } from "react-router-dom";
import MainPage from "./pages/MainPage";
import CreateNewPostPage from "./pages/CreateNewPostPage";
import PostListingPage from "./pages/PostListingPage";
import PostDetailPage from "./pages/PostDetailPage";
import PostFailurePage from "./pages/PostFailurePage";
import PostSuccessPage from "./pages/PostSuccessPage";
import PastRequestsDetailPage from "./pages/PastRequestsDetailPage";
import SelectMallPage from "./pages/SelectMallPage";
import LoginPage from "./pages/LoginPage";

export default function Router() {
  const routes = useRoutes([
    { path: "/", element: <PostListingPage /> },
    { path: "/*", element: <PostListingPage /> },
    { path: "create-new", element: <CreateNewPostPage /> },
    { path: "create-request", element: <MainPage /> },
    { path: "post-details", element: <PostDetailPage /> },
    { path: "post-details/:index", element: <PostDetailPage /> },
    { path: "past-request-details", element: <PastRequestsDetailPage /> },
    { path: "post-success", element: <PostSuccessPage /> },
    { path: "post-failure", element: <PostFailurePage /> },
    { path: "select-mall", element: <SelectMallPage /> },
    { path: "login", element: <LoginPage /> },
  ]);

  return routes;
}
