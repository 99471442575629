export default function Language(
  mall = "default",
  activeLanguage = "tr",
  request,
  variant = ""
) {
  const word = {
    default: {
      tr: {
        // header
        createPost: "GÖNDERİ OLUŞTUR",
        // select mall
        mall: "AVM",
        selectMall: "AVM Seçiniz",
        continue: "DEVAM ET",
        // post listing
        welcome: "Handsfree'ye hoşgeldiniz! Lütfen giriş yapın.",
        login: "Giriş Yap",
        createHandsfree: "{variant} Talebi Oluştur",
        handsfreeRequests: "{variant} Talepleri",
        emptyHandsfreeRequests: "{variant} talebiniz bulunmamaktadır.",
        awaitingStore: "Mağazadan onay bekleniyor",
        awaitingYou: "Sizden onay bekleniyor",
        readyPackages: "Teslime Hazır Paketler",
        pickup: "Teslim alınabilir",
        emptyReadyPackages: "Teslime hazır olan paketiniz bulunmamaktadır.",
        onthewayPackges: "Yoldaki Paketler",
        ontheway: "Yolda",
        emptyOnthewayPackages: "Yolda olan paketiniz bulunmamaktadır.",
        storePackages: "Mağazadaki Paketler",
        inthestore: "Mağazada",
        emptyStorePackages: "Mağazada paketiniz bulunmamaktadır.",
        pastRequests: "Geçmiş Talepler",
        completed: "Tamamlandı",
        canceled: "İptal edildi",
        emptyPastRequests: "Geçmiş talebiniz bulunmamaktadır.",
        // last update
        lastUpdate: "Son Güncelleme",
        refresh: "Yenile",
        version: "Versiyon",
        // create request
        selectStoreTitle: "Lütfen hizmet almak istediğiniz mağazayı seçiniz",
        selectPhone: "Lütfen telefon numaranızı girin",
        firm: "Mağaza",
        name: "Ad",
        lastname: "Soyad",
        phone: "Telefon",
        roomNo: "Oda No",
        code: "Kod",
        codeAgain: "Tekrar Kod Gönder",
        conditions1: "{variant} hizmetinden faydalanmak için",
        conditions2: "Kullanım Koşulları",
        conditions3: "ve ücretlendirmeleri kabul ediyorum.",
        taxFree: "Aldığım ürünlerde tax refund özelliğini kullanmak istiyorum.",
        // confirm packages
        pendingPackages: "Onay Bekleyen Paketler",
        timeLimit:
          "Hands Free gönderilerimi en az 1 saat sonra alacağımı kabul ediyorum.",
        create: "OLUŞTUR",
        beingCreated: "Oluşturuluyor...",
        cancel: "İPTAL",
        canceling: "İptal Ediliyor...",
        confirmSuccessMessage:
          "Paketleri onayladınız, iyi alışverişler dileriz.",
        rejectSuccessMessage:
          "Paketler iptal edilmiştir, iyi alışverişler dileriz.",

        // detail
        request: "Talep",
        package: "Paket",
        deliveryInfo: "Teslimat Bilgisi",
        deliveryStatus: "Teslimat Durumu",
        unknown: "Bilinmiyor",
        approved: "Onaylandı",
        rejected: "Talep reddedildi",
        deliveryCode: "Teslimat Şifresi",
        creationDate: "Oluşturulma Tarihi",
        store: "Mağaza",
        orderNo: "Sipariş No",
        deadlineForDelivery: "Son Teslim Tarihi",
        navigation: "Yol Tarifi",
        cargoTrackingNo: "Kargo Takip Numarası",
        // modals
        success: "Başarılı",
        successMessage: "Talep başarıyla oluşturulmuştur.",
        error: "Hata",
        errorMessage: "İşlem gerçekleştirilemedi.",
        okay: "Tamam",
        close: "Kapat",
        agree: "Onaylıyorum",
        consent:
          "6563 sayılı kanun kapsamında, Avm Lojistik Tic. A.Ş tarafından tarafıma verilmekte olan hizmetler kapsamında, herhangi bir iletişim aracı kullanarak (SMS, e-posta, telefon, faks, çağrı merkezi, v.b. gibi) tarafıma her türlü bilgilendirme, tanıtım, reklam, ürün teklifleri, promosyonlar, kampanyalar, memnuniyet değerlendirme çalışmaları ve duyuruların iletilmesi amacıyla tarafımla iletişime geçilmesine açık rıza veriyorum.",
      },
      en: {
        // header
        createPost: "CREATE POST",
        // select mall
        mall: "Shopping Center",
        selectMall: "Select Shopping Center",
        continue: "CONTINUE",
        // post listing
        welcome: "Welcome to Handsfree! Please log in.",
        login: "Login",
        createHandsfree: "Request {variant}",
        handsfreeRequests: "{variant} Requests",
        emptyHandsfreeRequests: "You do not have a {variant} request.",
        awaitingStore: "Awaiting approval from the store",
        awaitingYou: "Awaiting approval from you",
        readyPackages: "Packages ready for pick-up",
        pickup: "Ready for pick-up",
        emptyReadyPackages: "You do not have any packages ready for pick-up.",
        onthewayPackges: "Packages on delivery",
        ontheway: "On the Way",
        emptyOnthewayPackages: "You do not have any packages on delivery.",
        storePackages: "Packages In the Store",
        inthestore: "In the Store",
        emptyStorePackages: "You do not have any packages in the store.",
        pastRequests: "Past Requests",
        completed: "Completed",
        canceled: "Canceled",
        emptyPastRequests: "You do not have any past requests.",
        // last update
        lastUpdate: "Latest Update",
        refresh: "Refresh",
        version: "Version",
        // create request
        selectStoreTitle:
          "Please select the store you would like to receive service from",
        selectPhone: "Please enter your telephone number",
        firm: "Store",
        name: "Name",
        lastname: "Lastname",
        phone: "Phone",
        roomNo: "Room No",
        code: "Code",
        codeAgain: "Resend Code",
        conditions1: "I agree to the",
        conditions2: "conditions",
        conditions3: "and charges for the {variant} service.",
        taxFree: "I would like to use tax refund on my purchases.",
        // confirm packages
        pendingPackages: "Packages Pending Approval",
        timeLimit:
          "I agree to receive my Hands Free packages at least 1 hour later.",
        create: "CREATE",
        beingCreated: "Being Created...",
        cancel: "CANCEL",
        canceling: "Canceling...",
        confirmSuccessMessage:
          "You have confirmed the packages, have a good shopping experience.",
        rejectSuccessMessage:
          "Packages have been canceled, we wish you a good shopping experience.",
        // detail
        request: "Request",
        package: "Package",
        deliveryInfo: "Delivery Info",
        deliveryStatus: "Delivery Status",
        unknown: "Unknown",
        approved: "Approved",
        rejected: "Rejected declined",
        deliveryCode: "Delivery Code",
        creationDate: "Creation Date",
        store: "Store",
        orderNo: "Order No",
        deadlineForDelivery: "Deadline for Delivery",
        navigation: "Navigation",
        cargoTrackingNo: "Cargo Tracking Number",
        // modals
        success: "Success",
        successMessage: "The request was successfully created.",
        error: "Error",
        errorMessage: "Transaction could not be processed.",
        okay: "Okay",
        close: "Close",
        agree: "Approve",
        consent:
          "Within the scope of Law No. 6563, within the scope of the services provided to me by Avm Lojistik Tic. A.Ş., I give my explicit consent to be contacted by any means of communication (such as SMS, e-mail, telephone, fax, call centre, etc.) for the purpose of communicating all kinds of information, promotion, advertisement, product offers, promotions, campaigns, satisfaction evaluation studies and announcements to me.",
      },
    },

    galataport: {
      tr: {
        // header
        createPost: "TALEP OLUŞTUR",
        // select mall
        mall: "AVM",
        selectMall: "AVM Seçiniz",
        continue: "DEVAM ET",
        // post listing
        welcome: "Bags-Free'ye hoşgeldiniz! Lütfen giriş yapın.",
        login: "Giriş Yap",
        createHandsfree: "{variant} Talebi Oluştur",
        handsfreeRequests: "{variant} Talepleri",
        emptyHandsfreeRequests: "{variant} talebiniz bulunmamaktadır.",
        awaitingStore: "Mağazadan onay bekleniyor",
        awaitingYou: "Sizden onay bekleniyor",
        readyPackages: "Teslime Hazır Paketler",
        pickup: "Teslim alınabilir",
        emptyReadyPackages: "Teslime hazır olan paketiniz bulunmamaktadır.",
        onthewayPackges: "Yoldaki Paketler",
        ontheway: "Yolda",
        emptyOnthewayPackages: "Yolda olan paketiniz bulunmamaktadır.",
        storePackages: "Mağazadaki Paketler",
        inthestore: "Mağazada",
        emptyStorePackages: "Mağazada paketiniz bulunmamaktadır.",
        pastRequests: "Geçmiş Talepler",
        completed: "Tamamlandı",
        canceled: "İptal edildi",
        emptyPastRequests: "Geçmiş talebiniz bulunmamaktadır.",
        // last update
        lastUpdate: "Son Güncelleme",
        refresh: "Yenile",
        version: "Versiyon",
        // create request
        selectStoreTitle: "Lütfen hizmet almak istediğiniz mağazayı seçiniz",
        selectPhone: "Lütfen telefon numaranızı girin",
        firm: "Mağaza",
        name: "Ad",
        lastname: "Soyad",
        phone: "Telefon",
        roomNo: "Oda No",
        code: "Kod",
        codeAgain: "Tekrar Kod Gönder",
        conditions1: "{variant} hizmetinden faydalanmak için",
        conditions2: "Kullanım Koşulları",
        conditions3: "ve ücretlendirmeleri kabul ediyorum.",
        taxFree: "Aldığım ürünlerde tax refund özelliğini kullanmak istiyorum.",
        // confirm packages
        pendingPackages: "Onay Bekleyen Paketler",
        timeLimit:
          "Bags-Free gönderilerimi en az 1 saat sonra alacağımı kabul ediyorum.",
        create: "TALEBİNİZİ ONAYLAYIN",
        beingCreated: "Oluşturuluyor...",
        cancel: "İPTAL",
        canceling: "İptal Ediliyor...",
        confirmSuccessMessage:
          "Paketleri onayladınız, iyi alışverişler dileriz.",
        rejectSuccessMessage:
          "Paketler iptal edilmiştir, iyi alışverişler dileriz.",

        // detail
        request: "Talep",
        package: "Paket",
        deliveryInfo: "Teslimat Bilgisi",
        deliveryStatus: "Teslimat Durumu",
        unknown: "Bilinmiyor",
        approved: "Onaylandı",
        rejected: "Talep reddedildi",
        deliveryCode: "Teslimat Şifresi",
        creationDate: "Oluşturulma Tarihi",
        store: "Mağaza",
        orderNo: "Sipariş No",
        deadlineForDelivery: "Son Teslim Tarihi",
        navigation: "Yol Tarifi",
        cargoTrackingNo: "Kargo Takip Numarası",
        paymentStatus: "Ödeme Durumu",
        paid: "Ödenmiş",
        cash: "Nakit",
        creditCard: "Kredi Kartı",
        unpaid: "Ödenmemiş",
        paymentMethod: "Ödeme Yöntemi",
        online: "Online",
        cashOrPost: "Nakit veya post",
        // modals
        success: "Başarılı",
        successMessage: "Talep başarıyla oluşturulmuştur.",
        error: "Hata",
        errorMessage: "İşlem gerçekleştirilemedi.",
        okay: "Tamam",
        close: "Kapat",
        agree: "Onaylıyorum",
        consent:
          "6563 sayılı kanun kapsamında, Avm Lojistik Tic. A.Ş tarafından tarafıma verilmekte olan hizmetler kapsamında, herhangi bir iletişim aracı kullanarak (SMS, e-posta, telefon, faks, çağrı merkezi, v.b. gibi) tarafıma her türlü bilgilendirme ve duyuruların iletilmesi amacıyla tarafımla iletişime geçilmesine açık rıza veriyorum.",
      },
      en: {
        // header
        createPost: "CREATE REQUEST",
        // select mall
        mall: "Shopping Center",
        selectMall: "Select Shopping Center",
        continue: "CONTINUE",
        // post listing
        welcome: "Welcome to Bags-Free! Please log in.",
        login: "Login",
        createHandsfree: "Request {variant}",
        handsfreeRequests: "{variant} Requests",
        emptyHandsfreeRequests: "You do not have a {variant} request.",
        awaitingStore: "Awaiting approval from the store",
        awaitingYou: "Awaiting approval from you",
        readyPackages: "Packages ready for pick-up",
        pickup: "Ready for pick-up",
        emptyReadyPackages: "You do not have any packages ready for pick-up.",
        onthewayPackges: "Packages on delivery",
        ontheway: "On the Way",
        emptyOnthewayPackages: "You do not have any packages on delivery.",
        storePackages: "Packages In the Store",
        inthestore: "In the Store",
        emptyStorePackages: "You do not have any packages in the store.",
        pastRequests: "Past Requests",
        completed: "Completed",
        canceled: "Canceled",
        emptyPastRequests: "You do not have any past requests.",
        // last update
        lastUpdate: "Latest Update",
        refresh: "Refresh",
        version: "Version",
        // create request
        selectStoreTitle:
          "Please select the store you would like to receive service from",
        selectPhone: "Please enter your telephone number",
        firm: "Store",
        name: "Name",
        lastname: "Lastname",
        phone: "Phone",
        roomNo: "Room No",
        code: "Code",
        codeAgain: "Resend Code",
        conditions1: "I agree to the",
        conditions2: "conditions",
        conditions3: "and charges for the {variant} service.",
        taxFree: "I would like to use tax refund on my purchases.",
        // confirm packages
        pendingPackages: "Packages Pending Approval",
        timeLimit:
          "I agree to receive my Bags-Free packages at least 1 hour later.",
        create: "APPROVE",
        beingCreated: "Being Created...",
        cancel: "CANCEL",
        canceling: "Canceling...",
        confirmSuccessMessage:
          "You have confirmed the packages, have a good shopping experience.",
        rejectSuccessMessage:
          "Packages have been canceled, we wish you a good shopping experience.",
        // detail
        request: "Request",
        package: "Package",
        deliveryInfo: "Delivery Info",
        deliveryStatus: "Delivery Status",
        unknown: "Unknown",
        approved: "Approved",
        rejected: "Rejected declined",
        deliveryCode: "Delivery Code",
        creationDate: "Creation Date",
        store: "Store",
        orderNo: "Order No",
        deadlineForDelivery: "Deadline for Delivery",
        navigation: "Navigation",
        cargoTrackingNo: "Cargo Tracking Number",
        paymentStatus: "Payment Status",
        paid: "Paid",
        cash: "Cash",
        creditCard: "Credit Card",
        unpaid: "Unpaid",
        paymentMethod: "Payment Method",
        online: "Online",
        cashOrPost: "Cash or post",
        // modals
        success: "Success",
        successMessage: "The request was successfully created.",
        error: "Error",
        errorMessage: "Transaction could not be processed.",
        okay: "Okay",
        close: "Close",
        agree: "Approve",
        consent:
          "Within the scope of Law No. 6563, within the scope of the services provided to me by Avm Lojistik Tic. A.Ş., I give my explicit consent to be contacted by any means of communication (such as SMS, e-mail, telephone, fax, call centre, etc.) for the purpose of communicating all kinds of information and announcements to me.",
      },
    },
  };

  return typeof word[mall] == "undefined"
    ? word["default"][activeLanguage][request]?.replace("{variant}", variant)
    : word[mall][activeLanguage][request]?.replace("{variant}", variant);
}
