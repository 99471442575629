import axios from "axios";
import {
  BASE_URL,
  KEY,
  SECRET,
  DEFAULT_KEY,
  DEFAULT_SECRET,
  TCMB_BASE_URL,
} from ".";

export default {
  GetToken() {
    return axios({
      method: "post",
      url: `${BASE_URL}/get-token`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        key: KEY,
        secret: SECRET,
      },
    });
  },

  GetDefaultToken() {
    return axios({
      method: "post",
      url: `${BASE_URL}/get-token`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        key: DEFAULT_KEY,
        secret: DEFAULT_SECRET,
      },
    });
  },

  GetMallToken(token, uri) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/get-mall-token`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        uri: uri,
      },
    });
  },

  CreateCustomer(token, name, lastname, phone) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/customer-create`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        name: name,
        lastname: lastname,
        phone: phone,
      },
    });
  },

  GetCustomer(token, phone) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/get-customer`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        phone: phone,
      },
    });
  },

  ThirdPartyCustomerQuery(token, customerID, uri, defaultLanguage) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/third-party-customer-query`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        customerID: customerID,
        uri: uri,
        defaultLanguage: defaultLanguage,
      },
    });
  },

  GetTokenInfo(token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/token-info`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  },

  CreateOrder(token, name, lastname, phone, locationID, additionalNote) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/order-create`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        name: name,
        lastname: lastname,
        phone: phone,
        locationID: locationID,
        additionalNote: additionalNote,
      },
    });
  },

  CustomerOrders(token, customerID, mallID) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/order-list`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        customerID: customerID,
        mallID: mallID,
      },
    });
  },

  CustomerOrdersByMallID(token, customerID, mallID) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/order-list`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        customerID: customerID,
        mallID: mallID,
      },
    });
  },

  CustomerPosts(token, customerID, mallID) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/post-list`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        customerID: customerID,
        mallID: mallID,
      },
    });
  },

  CustomerOrderConfirm(token, customerID, orderID) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/order-customer-confirm`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        customerID: customerID,
        orderID: orderID,
      },
    });
  },

  CustomerOrderReject(token, customerID, orderID) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/order-customer-reject`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        customerID: customerID,
        orderID: orderID,
      },
    });
  },

  GetLocationList(token, branchID) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/location-list`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        branchID: branchID,
      },
    });
  },

  GetMalls(token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/get-mall-list`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  },

  SendSMS(token, phone, message) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/send-sms`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        phone: phone,
        message: message,
      },
    });
  },

  GetAgreement(token, id, language) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/agreement${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        language: language,
      },
    });
  },

  SetLanguage(token, customerID, defaultLanguage) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/set-customer-default-language`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        customerID: customerID,
        defaultLanguage: defaultLanguage,
      },
    });
  },

  GetExchangeRate() {
    return axios.get(TCMB_BASE_URL);
  },
};
